import React, { useState } from 'react';
import NetlifyForm from 'react-netlify-form';
import { Heading, Text, Box, Flex, Button } from 'rebass';
import { Label, Input, Textarea, Radio, Checkbox } from '@rebass/forms';
import fiText from '../locales/fi.js';
import { useTheme } from '@material-ui/core';

const trigTagManager = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmission',
    formName: 'Yhteydenotto Tilaratkaisu-sivulla',
  });
  console.log('form sent to tag manager');
};

const MultiselectCheckbox = ({ options, onChange }) => {
  const [data, setData] = React.useState(options);

  const toggle = index => {
    const newData = [...data];
    newData.splice(index, 1, {
      label: data[index].label,
      checked: !data[index].checked,
    });
    setData(newData);
    onChange(newData.filter(x => x.checked));
  };

  return (
    <>
      {data.map((item, index) => (
        <label className="container" key={item.label}>
          {item.label}
          <input
            type="checkbox"
            checked={item.checked || false}
            onClick={() => toggle(index)}
          />
          <span class="checkmark"></span>
        </label>
      ))}
    </>
  );
};

const options = [
  { label: 'Raikas' },
  { label: 'Lämmin' },
  { label: 'Tumma' },
  { label: 'Uniikki' },
  { label: 'En tiedä' },
];

const FormNetlify2 = () => {
  const [tyylivalinnat, setTyylivalinnat] = useState('');

  const handleTyylivalinnat = data => {
    const selections = data.map(item => ' ' + item.label);
    const content = 'Sisustustyyli:' + selections.join();
    document.getElementById('sisustustyyli').value = content;
  };

  return (
    <NetlifyForm name="Yhteydenotto Tilaratkaisu">
      {({ loading, error, success }) => (
        <div>
          <Flex
            flexDirection="column"
            maxWidth={480}
            mx={'auto'}
            color={'beigeText'}
          >
            <input id="pax" type="hidden" name="Työpisteet" value="55" />

            <input
              id="huoneet"
              type="hidden"
              name="Neuvotteluhoneet"
              value="5"
            />
            <input
              id="sopimus"
              type="hidden"
              name="Sopimuksen kesto"
              value="3"
            />
            <input
              id="autopaikat"
              type="hidden"
              name="Autopaikkojen määrä"
              value="0"
            />
            <input id="ehdotus" type="hidden" name="Tilaehdotus" value="" />
            <input id="hinta" type="hidden" name="Hinta" value="" />
            <input
              id="kerrospohjaUrl"
              type="hidden"
              name="Kerrospohja"
              value=""
            />
            <input
              id="sisustustyyli"
              type="hidden"
              name="Sisustustyyli"
              value=""
            />
            <div style={{ padding: '15px', textAlign: 'left' }}>
              <label style={{ marginBottom: 16 }}>Valitse sisustustyyli:</label>
              <MultiselectCheckbox
                options={options}
                onChange={data => {
                  handleTyylivalinnat(data);
                }}
              />
            </div>
            <Box width={1} px="15px" mb={0} className="required">
              <Input
                required
                id="company2"
                name="company"
                type="text"
                placeholder="Yritys"
                bg={'blueDark'}
                sx={{ borderColor: 'turquoise' }}
              />
            </Box>
            <Box width={1} px="15px" mb={0} className="required">
              <Input
                required
                id="name2"
                name="name"
                type="text"
                placeholder="Nimi"
                bg={'blueDark'}
                sx={{ borderColor: 'turquoise' }}
              />
            </Box>
            <Box width={1} px="15px" mb={0} className="required">
              <Input
                required
                id="email2"
                name="email"
                type="email"
                placeholder="Sähköposti"
                bg={'blueDark'}
                sx={{ borderColor: 'turquoise' }}
              />
            </Box>
            <Box width={1} px="15px" mb={0} className="required">
              <Input
                required
                id="phone2"
                name="phone"
                type="text"
                placeholder="Puhelin"
                bg={'blueDark'}
                sx={{ borderColor: 'turquoise' }}
              />
            </Box>
            <Box width={1} px="15px" mb={1}>
              <Textarea
                id="message2"
                name="message"
                placeholder="Lisätietoja"
                bg={'blueDark'}
                sx={{ borderColor: 'turquoise' }}
              />
            </Box>
            <Box px="15px" mb={1}>
              <Button
                type="submit"
                bg={'redDarkButton'}
                color={'white'}
                width={200}
              >
                {fiText.btnSend}
              </Button>
            </Box>
          </Flex>
          <Box pt={1} fontWeight="600">
            {loading && (
              <Text mt={-1} fontStyle="italic">
                {fiText.sending}
              </Text>
            )}
            {error && (
              <Text mt={-1} fontStyle="italic">
                {fiText.formError}
              </Text>
            )}
            {success && (
              <Text mt={0} pb={2} fontStyle="italic">
                {trigTagManager()}
                Kiitos! Lähetimme tiedot sinulle myös sähköpostiin. Mikäli et
                saa viestiä muutaman minuutin kuluessa, tarkistathan
                roskapostikansiosi.
              </Text>
            )}
          </Box>
          <Text fontSize="15px" my="15px">
            Käsittelemme yhteystietojasi{' '}
            <a href="/tietosuojaseloste" target="_blank">
              Tietosuojaseloste
            </a>
            emme mukaisesti.
          </Text>
        </div>
      )}
    </NetlifyForm>
  );
};

export default FormNetlify2;
