import React, { Component } from 'react';
import InputRange from 'react-input-range'; //https://github.com/davidchin/react-input-range
import Slider from '@material-ui/core/Slider';

import { Heading, Flex, Box, Text, Image, Button } from 'rebass';

import './MuiSlider.css';

import TilaView from './PriceCounterView';

class PriceCounter extends React.Component {
  state = {
    paxValue: 55,
    roomsValue: 5,
    yearsValue: 3,
    parkingValue: 0,
  };

  handleRoomChange = value => {
    this.setState({ roomsValue: value });
  };
  handleYearChange = value => {
    this.setState({ yearsValue: value });
  };

  render() {
    let { paxValue, roomsValue, yearsValue, parkingValue } = this.state;

    const handlePaxChange = (event, newValue) => {
      this.setState({ paxValue: newValue });
      document.getElementById('pax').value = newValue;
    };
    const handleRoomsChange = (event, newValue) => {
      this.setState({ roomsValue: newValue });
      document.getElementById('huoneet').value = newValue;
    };
    const handleYearsChange = (event, newValue) => {
      this.setState({ yearsValue: newValue });
      document.getElementById('sopimus').value = newValue;
    };
    const handleParkingChange = (event, newValue) => {
      this.setState({ parkingValue: newValue });
      document.getElementById('autopaikat').value = newValue;
    };
    const valuetext = value => {
      return `${value} v`;
    };

    return (
      <Flex
        mx="auto"
        px={0}
        width={1}
        maxWidth="720px"
        flexDirection="column"
        justifyContent="center"
        textAlign="left"
      >
        <Flex
          mx="auto"
          width={[9 / 10, 4 / 5]}
          maxWidth="540px"
          flexDirection="column"
          justifyContent="center"
        >
          <Text as="h4">Työpisteet</Text>
          <Slider
            defaultValue={55}
            aria-labelledby="discrete-slider-always"
            value={paxValue}
            step={1}
            min={10}
            max={100}
            marks={[
              {
                value: 10,
                label: '10',
              },
              {
                value: 100,
                label: '100',
              },
            ]}
            valueLabelDisplay="on"
            onChange={handlePaxChange}
          />
          <Text as="h4">Neuvotteluhuoneet</Text>
          <Slider
            defaultValue={5}
            aria-labelledby="discrete-slider-always"
            value={roomsValue}
            step={1}
            min={0}
            max={10}
            valueLabelDisplay="on"
            onChange={handleRoomsChange}
            marks={[
              {
                value: 0,
                label: '0',
              },
              {
                value: 10,
                label: '10',
              },
            ]}
          />
          <Text as="h4">Sopimuksen kesto</Text>
          <Slider
            defaultValue={3}
            aria-labelledby="discrete-slider-always"
            value={yearsValue}
            step={1}
            min={1}
            max={5}
            valueLabelDisplay="on"
            valueLabelFormat={valuetext}
            onChange={handleYearsChange}
            marks={[
              {
                value: 1,
                label: '1',
              },
              {
                value: 5,
                label: '5',
              },
            ]}
          />
          <Text as="h4">Autopaikkojen määrä</Text>
          <Slider
            defaultValue={0}
            aria-labelledby="discrete-slider-always"
            value={parkingValue}
            step={1}
            min={0}
            max={30}
            valueLabelDisplay="on"
            onChange={handleParkingChange}
            marks={[
              {
                value: 0,
                label: '0',
              },
              {
                value: 30,
                label: '30',
              },
            ]}
          />
        </Flex>
        <Display
          pax={paxValue}
          rooms={roomsValue}
          years={yearsValue}
          parking={parkingValue}
          content={this.props.data}
          autopaikka={this.props.autopaikka}
        />
      </Flex>
    );
  }
}

class Display extends React.Component {
  state = {
    tila: 6,
    price: 0,
  };

  componentDidMount() {
    this.calculateTila();
    this.calculatePrice(this.state.tila);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.calculateTila();
      this.calculatePrice(this.state.tila);
    }
  }

  inRange = (x, min, max) => {
    return x >= min && x <= max;
  };

  calculateTila = () => {
    let { pax, rooms } = this.props;

    // Tila 119
    if (this.inRange(pax, 0, 10) && this.inRange(rooms, 0, 2)) {
      return this.setState({ tila: 0 });
    }
    if (this.inRange(pax, 11, 14) && this.inRange(rooms, 0, 1)) {
      return this.setState({ tila: 0 });
    }

    // Tila 152
    if (this.inRange(pax, 0, 10) && this.inRange(rooms, 3, 3)) {
      return this.setState({ tila: 1 });
    }
    if (this.inRange(pax, 11, 14) && this.inRange(rooms, 2, 3)) {
      return this.setState({ tila: 1 });
    }
    if (this.inRange(pax, 15, 18) && this.inRange(rooms, 0, 3)) {
      return this.setState({ tila: 1 });
    }

    // Tila 237
    if (this.inRange(pax, 0, 18) && this.inRange(rooms, 4, 4)) {
      return this.setState({ tila: 3 });
    }
    if (this.inRange(pax, 19, 27) && this.inRange(rooms, 0, 2)) {
      return this.setState({ tila: 3 });
    }
    if (this.inRange(pax, 28, 30) && this.inRange(rooms, 0, 1)) {
      return this.setState({ tila: 3 });
    }

    // Tila: 252,5
    if (this.inRange(pax, 0, 18) && this.inRange(rooms, 5, 6)) {
      return this.setState({ tila: 5 });
    }
    if (this.inRange(pax, 19, 27) && this.inRange(rooms, 3, 4)) {
      return this.setState({ tila: 5 });
    }
    if (this.inRange(pax, 28, 28) && this.inRange(rooms, 2, 4)) {
      return this.setState({ tila: 5 });
    }
    if (this.inRange(pax, 29, 30) && this.inRange(rooms, 2, 3)) {
      return this.setState({ tila: 5 });
    }

    // Tila: 321,5
    if (this.inRange(pax, 0, 18) && this.inRange(rooms, 7, 7)) {
      return this.setState({ tila: 6 });
    }
    if (this.inRange(pax, 19, 28) && this.inRange(rooms, 5, 6)) {
      return this.setState({ tila: 6 });
    }
    if (this.inRange(pax, 29, 30) && this.inRange(rooms, 4, 6)) {
      return this.setState({ tila: 6 });
    }
    if (this.inRange(pax, 31, 44) && this.inRange(rooms, 0, 1)) {
      return this.setState({ tila: 6 });
    }

    // Tila: 377,5
    if (this.inRange(pax, 0, 18) && this.inRange(rooms, 8, 8)) {
      return this.setState({ tila: 7 });
    }
    if (this.inRange(pax, 19, 30) && this.inRange(rooms, 7, 8)) {
      return this.setState({ tila: 7 });
    }
    if (this.inRange(pax, 31, 34) && this.inRange(rooms, 2, 5)) {
      return this.setState({ tila: 7 });
    }

    // Tila: 404
    if (this.inRange(pax, 35, 44) && this.inRange(rooms, 2, 4)) {
      return this.setState({ tila: 8 });
    }
    if (this.inRange(pax, 45, 54) && this.inRange(rooms, 0, 4)) {
      return this.setState({ tila: 8 });
    }

    // Tila: 475
    if (this.inRange(pax, 0, 18) && this.inRange(rooms, 9, 10)) {
      return this.setState({ tila: 9 });
    }
    if (this.inRange(pax, 19, 30) && this.inRange(rooms, 9, 9)) {
      return this.setState({ tila: 9 });
    }
    if (this.inRange(pax, 31, 34) && this.inRange(rooms, 6, 8)) {
      return this.setState({ tila: 9 });
    }
    if (this.inRange(pax, 35, 54) && this.inRange(rooms, 5, 7)) {
      return this.setState({ tila: 9 });
    }
    if (this.inRange(pax, 55, 65) && this.inRange(rooms, 0, 4)) {
      return this.setState({ tila: 9 });
    }
    if (this.inRange(pax, 66, 75) && this.inRange(rooms, 0, 2)) {
      return this.setState({ tila: 9 });
    }

    // Tila: 523
    if (this.inRange(pax, 19, 30) && this.inRange(rooms, 10, 10)) {
      return this.setState({ tila: 10 });
    }
    if (this.inRange(pax, 31, 34) && this.inRange(rooms, 9, 10)) {
      return this.setState({ tila: 10 });
    }
    if (this.inRange(pax, 35, 54) && this.inRange(rooms, 8, 9)) {
      return this.setState({ tila: 10 });
    }
    if (this.inRange(pax, 55, 65) && this.inRange(rooms, 5, 5)) {
      return this.setState({ tila: 10 });
    }
    if (this.inRange(pax, 66, 75) && this.inRange(rooms, 3, 5)) {
      return this.setState({ tila: 10 });
    }
    if (this.inRange(pax, 76, 100) && this.inRange(rooms, 0, 1)) {
      return this.setState({ tila: 10 });
    }

    // Tila: 658
    if (this.inRange(pax, 55, 75) && this.inRange(rooms, 6, 7)) {
      return this.setState({ tila: 11 });
    }

    // Tila: 715,5
    if (this.inRange(pax, 35, 54) && this.inRange(rooms, 10, 10)) {
      return this.setState({ tila: 12 });
    }
    if (this.inRange(pax, 55, 75) && this.inRange(rooms, 8, 10)) {
      return this.setState({ tila: 12 });
    }
    if (this.inRange(pax, 76, 100) && this.inRange(rooms, 2, 10)) {
      return this.setState({ tila: 12 });
    }
    return console.log('No tila matches');
  };

  calculatePrice = tila => {
    let { years } = this.props;

    if (years === 1) {
      this.setState({
        price:
          this.props.content[tila].node.tilanNeliot *
          this.props.content[tila].node.hintaNelio,
      });
    }
    if (years === 2) {
      this.setState({
        price:
          this.props.content[tila].node.tilanNeliot *
          this.props.content[tila].node.hintaNelio2,
      });
    }
    if (years === 3) {
      this.setState({
        price:
          this.props.content[tila].node.tilanNeliot *
          this.props.content[tila].node.hintaNelio3,
      });
    }
    if (years === 4) {
      this.setState({
        price:
          this.props.content[tila].node.tilanNeliot *
          this.props.content[tila].node.hintaNelio4,
      });
    }
    if (years === 5) {
      this.setState({
        price:
          this.props.content[tila].node.tilanNeliot *
          this.props.content[tila].node.hintaNelio5,
      });
    }
  };

  render() {
    return (
      <div>
        <TilaView
          tila={this.state.tila}
          price={this.state.price}
          content={this.props.content}
          parking={this.props.parking}
          autopaikka={this.props.autopaikka}
        />
      </div>
    );
  }
}

export default PriceCounter;
