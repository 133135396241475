import React from 'react';
import Img from 'gatsby-image';

import { Heading, Flex, Box, Text, Image } from 'rebass';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'; //https://github.com/reactjs/react-tabs
import TransitionsModal from './ModalFloorsCounter';

class TilaView extends React.Component {
  constructor() {
    super();
    this.state = { tabIndex: 0 };
  }

  prettifyPrice() {
    let priceInitial =
      this.props.price + this.props.autopaikka * this.props.parking;
    let price = priceInitial;
    price = Math.round(price);
    price = price
      .toFixed(0)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return price;
  }

  fillHiddenPriceInput() {
    let priceInitial =
      this.props.price + this.props.autopaikka * this.props.parking;
    let price = priceInitial;
    price = Math.round(price);
    price = price
      .toFixed(0)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return price;
  }

  componentDidUpdate() {
    let query = document.querySelector(
      '.tilaehdotusImg .react-tabs__tab-panel--selected picture img'
    );

    let kerrospohjaUrl = query
      ? query.getAttribute('src')
      : 'https://www.datocms-assets.com/17594/1614934225-2krs-uusi.jpg?auto=compress%2Cformat&dpr=1&fm=jpg&w=1600';

    let ehdotus = document.getElementById('tilaehdotus').innerHTML;
    document.getElementById('ehdotus').value = ehdotus;
    document.getElementById('kerrospohjaUrl').value = kerrospohjaUrl.slice(8);
    document.getElementById('hinta').value = this.fillHiddenPriceInput();
  }

  render() {
    return (
      <Tabs
        className="tilaehdotusImg"
        selectedIndex={this.props.tila}
        onSelect={tabIndex => this.setState({ tabIndex })}
      >
        <TabList css={{ display: 'none' }}>
          {this.props.content.map(post => (
            <Tab key={post.node.nimi}>{post.node.nimi}</Tab>
          ))}
        </TabList>
        {this.props.content.map((post, index) => (
          <TabPanel key={post.node.nimi}>
            <Flex
              px={1}
              py={1}
              fontSize={3}
              bg={'beigeBg'}
              color={'blueDark'}
              maxWidth="680px"
              flexWrap="wrap"
              width={[9 / 10, 4 / 5]}
              maxWidth="540px"
              mx="auto"
            >
              <Flex width={[1, 1 / 2]}>
                <Text>
                  Tilaehdotus:{' '}
                  <strong>
                    <span id="tilaehdotus">{post.node.nimi}</span>
                  </strong>
                </Text>
              </Flex>
              <Flex width={[1, 1 / 2]}>
                <Text>
                  Hinta-arvio: <strong>{this.prettifyPrice()} €/kk</strong> +
                  alv{' '}
                </Text>
              </Flex>
            </Flex>
            <Flex
              py={1}
              mx="auto"
              width={[9 / 10, 4 / 5]}
              maxWidth="540px"
              flexWrap="wrap"
            >
              <Flex flexDirection="column" width={1} px={0}>
                <TransitionsModal index={index} img={post.node.kuva} />
              </Flex>
            </Flex>
          </TabPanel>
        ))}
      </Tabs>
    );
  }
}

export default TilaView;
