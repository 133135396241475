import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { Heading, Flex, Box, Text, Image } from 'rebass';
import Img from 'gatsby-image';
import { LinkBtnTransparent } from './Elements';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal({ img, index }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box
        id={`tila-${index + 1}`}
        onClick={handleOpen}
        maxWidth="100%"
        overflow="hidden"
        sx={{ position: 'relative', cursor: 'pointer' }}
      >
        <Img fluid={img.fluid} alt={img.alt} width={600} />
        <svg
          className="zoomIcon"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.90997 13.09 3 9.5 3C5.90997 3 3 5.90997 3 9.5C3 13.09 5.90997 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01001 14 5 11.99 5 9.5C5 7.01001 7.01001 5 9.5 5C11.99 5 14 7.01001 14 9.5C14 11.99 11.99 14 9.5 14ZM9 9V7H10V9H12V10H10V12H9V10H7V9H9Z"
            fill="#001826"
          />
        </svg>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} sx={{ margin: '0 auto !important' }}>
          <Box mx="auto" width={['100vw', '90vw']}>
            <Img fluid={img.fluid} alt={img.alt} width={1200} />
            <Box
              onClick={handleClose}
              py={1}
              color="#fff"
              textAlign="center"
              mb={'-30px'}
              sx={{ cursor: 'pointer' }}
            >
              Sulje
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
