import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';

import SEO from '../components/SEO';
import {
  Main,
  Section,
  Container,
  Btn,
  LinkBtn,
  LinkBtnRed,
  LinkBtnGold,
} from '../components/Elements';
import Header from '../components/Header';

import { Hero } from '../components/HeroPages';
import FormNetlify2 from '../components/FormNetlify2';
import NetlifyForm3 from '../components/FormNetlify3';
import { FourPoints } from '../components/FourPoints';
import { Heading, Flex, Box, Text, Image, Button } from 'rebass';

import TabView from '../components/Tabs';

import _map from 'lodash/map';

import PriceCounter from '../components/PriceCounter';
import fiText from '../locales/fi';
import ImageGallery from '../components/ImageGallery';

/* Todo: https://github.com/datocms/gatsby-source-datocms#modular-content-fields */

const Tilaratkaisu = () => {
  const data = useStaticQuery(tilaratkaisuQuery);
  const {
    seoMetaTags,
    content,
    heroLayer,
    imgHero,
    kerrospohjat,
    kerrospohjatH,
    laskeHinta,
    laskeHintaTeksti,
    lataaEsitePdf,
    lataaEsiteBtn,
    autopaikanHinta,
    lataaEsiteTeksti,
    lead,
    laskehintaAlateksti,
    otaYhteytta,
    otaYhteyttaAlateksti,
    otaYhteyttaTeksti,
    ryhdyToimeen,
    pyydaTarjousTeksti,
    ryhdyToimeenTeksti,
    tilaratkaisuList1,
    tilaratkaisuList2,
    tilaratkaisuList3,
    tilaratkaisuList4,
    title,
    valahdyksiaH,
  } = data.datoCmsTilaratkaisu;
  const { tausta2, tausta3 } = data.datoCmsSivusto;
  const { goodPoints, kuvagalleria } = data.datoCmsHighlight;
  const { edges } = data.tilat;
  const introLayer = 'rgba(41, 100, 116, 0.7)';

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Header />
      <Main bg={'turquoise'} color={'beigeText'} textAlign={'center'}>
        <Hero
          title={title}
          lead={lead}
          imgHero={imgHero}
          introLayer={introLayer}
          heroLayer={heroLayer}
          introColor={'beigeText'}
        />
        <Section>
          <Container flexDirection="column">
            <Text
              mx={'auto'}
              py={5}
              maxWidth={720}
              dangerouslySetInnerHTML={{
                __html: data.datoCmsTilaratkaisu.content,
              }}
            />
            <LinkBtnGold
              style={{ display: 'none', margin: '0 auto 80px' }}
              to="#tila-ja-hinta"
            >
              Laske hinta-arvio
            </LinkBtnGold>
            <Heading mt={3} fontSize={6} sx={{ zIndex: 1 }}>
              {valahdyksiaH}
            </Heading>
          </Container>
          <Container flexWrap="wrap" sx={{ zIndex: 1 }}>
            {_map(goodPoints, goodPoints => (
              <Box
                key={goodPoints.id}
                pt={4}
                pb={[0, 3]}
                px={2}
                width={[1, 1 / 5]}
                sx={{ zIndex: 1 }}
              >
                <Box height={60}>
                  <Image src={goodPoints.iconLight.url} width={40} />
                </Box>

                <Text
                  fontSize={0}
                  fontWeight={600}
                  letterSpacing={'2px'}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {goodPoints.text}
                </Text>
              </Box>
            ))}
          </Container>
          <Container flexDirection="column" textAlign="center">
            <Image
              ml={[0, '5%']}
              mt={'-550px'}
              display={['none', 'block']}
              src={tausta2.url}
              width={900}
              sx={{ position: 'absolute' }}
            />
            <Heading id="kerros" fontSize={6} my={3} sx={{ zIndex: 1 }}>
              {kerrospohjatH}
            </Heading>

            <TabView kerrospohjat={data.floors} />
            <div
              css={`
                display: none;
              `}
            >
              <Heading
                fontSize={6}
                textAlign="center"
                maxWidth={'720px'}
                mt={7}
                mx="auto"
              >
                {data.datoCmsLataaEsite.otsikko}
              </Heading>
              <Text maxWidth={630} mx={'auto'} mt={3} sx={{ zIndex: 1 }}>
                {data.datoCmsLataaEsite.teksti}
              </Text>
              <a
                href={data.datoCmsLataaEsite.esite.url}
                target="_blank"
                className="downloadLink"
                css={{ marginTop: 20, textDecoration: 'underline' }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                    fill="#DFD0B7"
                  />
                </svg>
                Lataa kohde-esite (PDF)
              </a>
              <a
                href={data.datoCmsLataaEsite.esite2.url}
                target="_blank"
                className="downloadLink"
                css={{ marginTop: 10, textDecoration: 'underline' }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                    fill="#DFD0B7"
                  />
                </svg>
                Lataa sisustustyylikooste (PDF)
              </a>
            </div>
            {/*<NetlifyForm3
              urlEsite={data.datoCmsLataaEsite.esite.url}
              urlEsiteSisustus={data.datoCmsLataaEsite.esite2.url}
            />*/}
          </Container>
          <div
            css={`
              display: none;
            `}
            className="cont_fourpoints"
            mt={[4]}
            mb={5}
            flexDirection="column"
          >
            <Heading
              fontSize={6}
              textAlign="center"
              maxWidth={'720px'}
              mx="auto"
            >
              {ryhdyToimeen}
            </Heading>
            <Text my={5} mx={'auto'} textAlign="center" maxWidth={'720px'}>
              {ryhdyToimeenTeksti}
            </Text>
            <Box
              mx="auto"
              width={[1, 1, '66.7%']}
              maxWidth={'720px'}
              textAlign="left"
            >
              <FourPoints text={tilaratkaisuList1} number={'1'} />
              <FourPoints text={tilaratkaisuList2} number={'2'} />
              <FourPoints text={tilaratkaisuList3} number={'3'} />
              <FourPoints text={tilaratkaisuList4} number={'4'} />
            </Box>
          </div>

          <Container
            id="ota-yhteytta"
            mb={6}
            sx={{ display: 'none !important' }}
          >
            <Flex
              py={4}
              px={1}
              mx="auto"
              flexDirection="column"
              width={1}
              maxWidth={1088}
              bg="blueDark"
              color="beigeText"
              sx={{
                backgroundImage: `url(${tausta3.url})`,
                backgroundSize: 'cover',
              }}
            >
              <Heading fontSize={4} my={3} sx={{ zIndex: 1 }}>
                {otaYhteytta}
              </Heading>
              <Text mb={2} mx={'auto'} textAlign="center" maxWidth={'720px'}>
                {otaYhteyttaTeksti}
              </Text>
              <FormNetlify2 />
              <Text
                mt="20px"
                pt={30}
                dangerouslySetInnerHTML={{
                  __html: otaYhteyttaAlateksti,
                }}
              />
            </Flex>
          </Container>
          <Container>
            <ImageGallery data={kuvagalleria} />
          </Container>
        </Section>
      </Main>
    </Fragment>
  );
};

const tilaratkaisuQuery = graphql`
  {
    datoCmsSivusto {
      tausta2 {
        url
      }
      tausta3 {
        url
      }
    }
    datoCmsHighlight {
      goodPoints {
        id
        text
        iconLight {
          url
        }
      }
      kuvagalleria {
        image {
          url
          width
          height
        }
        width
        height
      }
    }
    datoCmsLataaEsite {
      otsikko
      teksti
      esite {
        url
      }
      esite2 {
        url
      }
    }
    floors: allDatoCmsKerrospohjat(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          otsikko
          otsikkoMobiilissa
          kuva {
            fluid(maxWidth: 1800) {
              ...GatsbyDatoCmsFluid
            }
            url
            alt
          }
        }
      }
    }
    tilat: allDatoCmsTilat(sort: { fields: tilanNeliot, order: ASC }) {
      edges {
        node {
          id
          nimi
          tilanNeliot
          hintaNelio
          hintaNelio2
          hintaNelio3
          hintaNelio4
          hintaNelio5
          kuva {
            fluid(
              maxWidth: 1100
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
            url
            alt
          }
        }
      }
    }
    datoCmsTilaratkaisu {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content
      heroLayer {
        alpha
        blue
        green
        red
      }
      imgHero {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      kerrospohjatH
      laskeHinta
      laskeHintaTeksti
      pyydaTarjousTeksti
      lead
      laskehintaAlateksti
      otaYhteyttaAlateksti
      otaYhteyttaTeksti
      autopaikanHinta
      ryhdyToimeen
      ryhdyToimeenTeksti
      tilaratkaisuList1
      tilaratkaisuList2
      tilaratkaisuList3
      tilaratkaisuList4
      title
      valahdyksiaH
    }
  }
`;

export default Tilaratkaisu;
